<template>
    <div id="article-list">

        <van-row :gutter="20">
            <van-col span="12" v-for="article in articles" :key="article.articleId" :title="article.title">

                <router-link :to="`/detail/${article.articleId}`">
                    <div class="article-item">
                        <van-image :src="article.cover" width="100%" height="15rem" fit="cover">
                            <template v-slot:loading>
                                <van-loading type="spinner" size="20" />
                            </template>
                        </van-image>

                        <p class="title">{{decryptTitle(article.title)}}</p>
                    </div>
                </router-link>

            </van-col>
        </van-row>

        <div class="van-clearfix"></div>
    </div>
</template>

<script>
import crypto from '../../utils/crypto_utils';

export default {
    name: 'ArticleList',
    props: {
        articles: {
            type: Array,
            default: () => [],
            required: true
        }
    },
    methods: {
        /**
         * 解密标题内容
         * @param {加密过的标题} title 
         */
        decryptTitle(title) {
            return crypto.decryptTitle(title)
        }
    }
}
</script>

<style lang="stylus" scoped>
    #article-list
        margin-top: 16px
        overflow: hidden
      
        .van-row
            text-align: center

            div.article-item
                position: relative
                margin-bottom: 1.2em

            .title
                position: absolute
                left: 0
                bottom: 0
                width: 100%
                margin: 0
                color: white
                font-size: 0.8em
                background-color: #0000004f
                overflow: hidden
                white-space: nowrap
                text-overflow: ellipsis
                line-height: 2em
                padding-left: 0.5em;
</style>