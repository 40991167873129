<template>
    <div class="home_page">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="van-clearfix">
            <van-swipe :autoplay="1500" lazy-render>
                <van-swipe-item v-for="article in article_list" :key="article.id">
                    <router-link :to="`/detail/${article.articleId}`">
                        <van-image :src="article.cover" width="100%" height="100%" fit="cover">
                            <template v-slot:loading>
                                <van-loading type="spinner" size="20" />
                            </template>
                        </van-image>
                    </router-link>
                </van-swipe-item>
            </van-swipe>

            <article-list :articles="article_list"></article-list>
        </van-pull-refresh>
    </div>
</template>

<script>
import ArticleList from '../../components/article-list'
import { getNewestArticles } from '../../api/article'

export default {
    name: 'home_page',
    components: {
        ArticleList
    },
    setup() {
    },
    data() {
        return {
            refreshing: false,
            article_list: []
        }
    },
    created() {
        this.onRefresh()
    },
    methods: {
        async fetchArticles() {
            this.refreshing = true
            this.article_list = await getNewestArticles()
            this.refreshing = false
        },
        onRefresh() {
            this.fetchArticles()
        }
    }
}
</script>

<style lang="stylus" scoped>
.van-swipe
    height: 200px

</style>